<template>
    <div v-show="props.compType === 'kitsShiftChange'">
        <div class="doc">
            <img
                class="transfer-icon"
                :src="transferIcon"
                alt="换班图标"
            />
            <p class="title">换班套件</p>
        </div>

        <div class="usage-scenarios">
            <div class="usage-scenarios-label">使用场景(不同场景套件功能不同)：</div>
            <el-radio-group class="usage-scenarios-radio-group" v-model="props.usageScenarios">
                <el-radio :label="1">查寝换班</el-radio>
                <!-- <el-radio :label="2">其他</el-radio> -->
            </el-radio-group>
            <div v-if="props.usageScenarios === 1" class="usage-scenarios-des">
                <p>查寝换班与宿舍查寝功能相关，需要共同使用</p>
                <p>当前套件与【申请人】组件同时存在时，申请人只能选择职工并且只能单选</p>
            </div>
        </div>

        <div class="shift-worker-audit-wrapper">
            <div class="shift-worker-audit">
                <span class="shift-worker-audit-label">换班人审核</span>
                <el-switch
                    v-model="props.shiftWorkerAudit"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="1"
                    :inactive-value="0"
                    @change="handlerShiftWorkerAuditChange"
                ></el-switch>
            </div>
            <div class="shift-worker-audit-des">
                <p v-if="props.shiftWorkerAudit === 1">
                    <span class="shift-worker-audit-des-status">开启状态：</span>
                    <span class="shift-worker-audit-des-text">需要换班人进行审核，审核通过才可成功换班。</span>
                </p>
                <p v-else>
                    <span class="shift-worker-audit-des-status">关闭状态：</span>
                    <span class="shift-worker-audit-des-text">审批流程完成后，即可成功换班，无需换班人审核。</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'KitsShiftChangeSettings',
    components: {},
    props: ["props", "getFormId"],
    data() {
        return {

        }
    },
    filters: {},
    computed: {
        transferIcon() {
            return require("@/assets/images/transfer-icon.png");
        },
    },
    watch: {},
    created() {
    },
    mounted() {},
    methods: {
        handlerShiftWorkerAuditChange() {}
    }
}
</script>

<style lang="scss" scoped>
.doc {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
    }

    .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}

.usage-scenarios {
    .usage-scenarios-label {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6D7073;
    }

    .usage-scenarios-radio-group {
        margin-top: 16px;
    }

    .usage-scenarios-des {
        box-sizing: border-box;
        margin-top: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: red;
        border-radius: 4px;

        p + p {
            margin-top: 3px;
        }
    }
}

.shift-worker-audit-wrapper {
    margin-top: 20px;

    .shift-worker-audit {
        display: flex;
        align-items: center;

        .shift-worker-audit-label {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-right: 10px;
        }
    }

    .shift-worker-audit-des {
        background: #F7F7F7;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 13px 10px 15px 8px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-top: 14px;

        .shift-worker-audit-des-status {
            color: #333333;
        }
    }
}
</style>
